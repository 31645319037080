@import "../../styles/commons.scss";

.task-list {
  margin: 2em 0;
  overflow: auto;
  height: 100%;
}

.task-card {
  display: flex;
  flex-direction: column;
  padding: 1em;
  border: 1px solid $grey-10;
  border-radius: 4px;
  background: $white;
  margin: 1em 0;

  .task-title {
    font-size: 1.5em;
    font-weight: 700;
  }

  .task-time {
    font-size: 1.5em;
    font-weight: 300;
  }
}

.remarks{
  display: block;
  padding-top: 1rem;
  opacity: 0.6;
}
.task-head{
  display: flex;
  justify-content: space-between;
}
.delete-task{
    height: 2em;
    width: 2em;
    border: none;
    border-radius: 50%;
}
.delete-task img{
  width: 1rem;
}
